exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-add-on-lenders-tsx": () => import("./../../../src/pages/add-on-lenders.tsx" /* webpackChunkName: "component---src-pages-add-on-lenders-tsx" */),
  "component---src-pages-check-for-claims-tsx": () => import("./../../../src/pages/check-for-claims.tsx" /* webpackChunkName: "component---src-pages-check-for-claims-tsx" */),
  "component---src-pages-contact-information-tsx": () => import("./../../../src/pages/contact-information.tsx" /* webpackChunkName: "component---src-pages-contact-information-tsx" */),
  "component---src-pages-eligibility-sorry-tsx": () => import("./../../../src/pages/eligibility-sorry.tsx" /* webpackChunkName: "component---src-pages-eligibility-sorry-tsx" */),
  "component---src-pages-error-tsx": () => import("./../../../src/pages/error.tsx" /* webpackChunkName: "component---src-pages-error-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lenders-tsx": () => import("./../../../src/pages/lenders.tsx" /* webpackChunkName: "component---src-pages-lenders-tsx" */),
  "component---src-pages-oops-tsx": () => import("./../../../src/pages/oops.tsx" /* webpackChunkName: "component---src-pages-oops-tsx" */),
  "component---src-pages-previous-details-tsx": () => import("./../../../src/pages/previous-details.tsx" /* webpackChunkName: "component---src-pages-previous-details-tsx" */),
  "component---src-pages-signature-tsx": () => import("./../../../src/pages/signature.tsx" /* webpackChunkName: "component---src-pages-signature-tsx" */),
  "component---src-pages-sorry-tsx": () => import("./../../../src/pages/sorry.tsx" /* webpackChunkName: "component---src-pages-sorry-tsx" */),
  "component---src-pages-thank-you-client-tsx": () => import("./../../../src/pages/thank-you-client.tsx" /* webpackChunkName: "component---src-pages-thank-you-client-tsx" */),
  "component---src-pages-thank-you-sorry-tsx": () => import("./../../../src/pages/thank-you-sorry.tsx" /* webpackChunkName: "component---src-pages-thank-you-sorry-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("./../../../src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */),
  "component---src-pages-thank-you-unable-to-verify-tsx": () => import("./../../../src/pages/thank-you-unable-to-verify.tsx" /* webpackChunkName: "component---src-pages-thank-you-unable-to-verify-tsx" */),
  "component---src-pages-upload-proof-of-id-tsx": () => import("./../../../src/pages/upload-proof-of-id.tsx" /* webpackChunkName: "component---src-pages-upload-proof-of-id-tsx" */),
  "component---src-pages-verify-cb-otp-tsx": () => import("./../../../src/pages/verify-cb-otp.tsx" /* webpackChunkName: "component---src-pages-verify-cb-otp-tsx" */),
  "component---src-pages-verify-otp-tsx": () => import("./../../../src/pages/verify-otp.tsx" /* webpackChunkName: "component---src-pages-verify-otp-tsx" */),
  "component---src-pages-we-will-be-in-touch-tsx": () => import("./../../../src/pages/we-will-be-in-touch.tsx" /* webpackChunkName: "component---src-pages-we-will-be-in-touch-tsx" */),
  "component---src-pages-welcome-back-to-tcgl-tsx": () => import("./../../../src/pages/welcome-back-to-tcgl.tsx" /* webpackChunkName: "component---src-pages-welcome-back-to-tcgl-tsx" */),
  "component---src-pages-welcome-back-tsx": () => import("./../../../src/pages/welcome-back.tsx" /* webpackChunkName: "component---src-pages-welcome-back-tsx" */),
  "component---src-pages-your-address-tsx": () => import("./../../../src/pages/your-address.tsx" /* webpackChunkName: "component---src-pages-your-address-tsx" */),
  "component---src-pages-your-details-tsx": () => import("./../../../src/pages/your-details.tsx" /* webpackChunkName: "component---src-pages-your-details-tsx" */)
}

