import { ApiService } from '@src/service';
import { AddressModel, DefendantModel, DefendantsReqBodyModel, LeadModel } from '@model';
import { generateUUID, parseUrlParams } from '@src/lib';
import { lpServicesService } from '@src/config';
import { CreditCheckProviderEnum, KbaResultEnum, VictaProductEnum } from '@enum';
import { RoptoSessionService } from '@src/service/ropto-session.service';

interface LdsInitConfig {
  baseUrl: string;
  opts: { productId: string; clientId: string; creditCheckProvider: CreditCheckProviderEnum };
  initHeaders?: HeadersInit;
}

export class LdsService extends ApiService {
  creditCheckProvider!: CreditCheckProviderEnum;

  resumeKey = parseUrlParams()?.resumeKey || generateUUID();

  setResumeGuid = (existingGuid: string) => {
    this.resumeKey = existingGuid;
  };

  constructor(args: LdsInitConfig) {
    const { baseUrl, opts, initHeaders } = args;
    super({ baseUrl, opts, initHeaders, serviceName: 'LDS-Service' });

    this.creditCheckProvider = opts.creditCheckProvider;

    this.logger(`ResumeKey: [${this.resumeKey}]`);
    RoptoSessionService.updateProductId(this.productId);
  }
  
  async submitLeadData(values: Partial<LeadModel>): Promise<{ leadId?: string; clientExists?: boolean; otpReference?: string }> {
    return this.post(`${this.environment}/lead`, {
      lead: {
        ...this.sessionValues,
        ...values,
        resumeKey: this.resumeKey,
        toe: lpServicesService.toe,
        instructionFor: VictaProductEnum.UNDISCLOSED_COMMISSION,
        groupAuthority: true,
        creditCheckProvider: this.creditCheckProvider,
      },
    });
  }
  
  async verifyOTP(reqBody?: {
    resumeKey: string;
    reference: string;
    code: string;
    introducerId?: number;
  }): Promise<{ kbaResult: KbaResultEnum }> {
    return this.post(`${this.environment}/verify-otp`, reqBody);
  }
  
  
  async resendOTP(reqBody?: { resumeKey: string; reference: string }): Promise<{ kbaResult: KbaResultEnum }> {
    return this.post(`${this.environment}/resend-otp`, reqBody);
  }

  async updateLeadData(values: Partial<LeadModel>): Promise<{ message: 'ok' }> {
    return this.patch(`${this.environment}/lead/${this.resumeKey}`, { ...values, creditCheckProvider: this.creditCheckProvider });
  }

  async defendants(reqBody?: DefendantsReqBodyModel): Promise<DefendantModel[]> {
    return this.post(`${this.environment}/defendants`, reqBody);
  }

  async creditCheckEligible(reqBody?: { email: string; dob: string }): Promise<{ creditCheckEligible: boolean }> {
    return this.post(`${this.environment}/credit-check-eligible`, reqBody);
  }

  async validateAndFetchPartialLead(verificationBody: {
    resumeKey: string;
    attemptedDobIso?: string;
    regNumber?: string;
  }): Promise<{ valid: boolean; partialLead: Partial<LeadModel>; createdAt: string }> {
    return this.post(`${this.environment}/resume-key-journey`, verificationBody);
  }

  async uploadViaPreSignedUrl(file: File, uploadProgressEl?: HTMLProgressElement): Promise<{ fileName: string; file: File }> {
    const presignedUrlPath = `${this.environment}/get-presigned-put-request`;

    const { uploadURL, Key } = await this.get<{ uploadURL: string; Key: string }>(presignedUrlPath, {
      headers: {
        ...this.Headers,
        'Content-Type': file.type,
      },
    });

    if (uploadProgressEl) {
      uploadProgressEl.value = 0;
    }

    return new Promise((resolve, reject) => {
      try {
        const xhr: XMLHttpRequest = new XMLHttpRequest();

        xhr.upload.onprogress = (event: ProgressEvent) =>
          event.lengthComputable && uploadProgressEl ? (uploadProgressEl.value = (event.loaded / event.total) * 100) : null;

        xhr.onerror = reject;

        xhr.onloadend = () => {
          if (xhr.readyState === 4 && xhr.status === 200) {
            try {
              resolve({ fileName: Key, file });
            } catch (e) {
              reject(e);
            }
          }
        };

        xhr.open('PUT', uploadURL, true);
        xhr.send(file);
      } catch (e) {
        this.logger(e);
        reject(e);
      }
    });
  }

  async uploadProofOfId(inputItem: { resumeKey: string; files: string[] }): Promise<{ message: 'ok' }> {
    return this.post(`${this.environment}/upload-proof-of-id`, inputItem);
  }

  async updatePreviousDetails(values: { previousAddresses?: AddressModel[]; previousNames?: string[] }): Promise<{ message: 'ok' }> {
    if (!values.previousAddresses.length && !values.previousNames.length) {
      return Promise.resolve({ message: 'ok' });
    }

    return this.updateLeadData(values);
  }
  
  async updateAddOnDefendants(values: { addOnCreditCheckDefendants?: DefendantModel[] }): Promise<{ message: 'ok' }> {
    if (!values.addOnCreditCheckDefendants.length) {
      return Promise.resolve({ message: 'ok' });
    }
    
    return this.updateLeadData(values);
  }
}
