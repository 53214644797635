import { AddressModel, DefendantModel, TelephoneModel } from '@src/model/interface';
import { ApiV2Service } from '@src/service/api-v2.service';
import { KbaResultEnum } from '@enum';

export enum CheckboardSoftCheckQuestionChoiceTypeEnum {
  SINGLE_CHOICE = 'SINGLE_CHOICE',
}

export interface CheckboardSoftCheckChoiceModel {
  choiceId: number;
  choiceText: string;
}

export interface CheckboardSoftCheckQuestionModel {
  questionId: number;
  questionText: string;
  choiceType: {
    type: CheckboardSoftCheckQuestionChoiceTypeEnum;
  };
  choices: CheckboardSoftCheckChoiceModel[];
  note: string;
}

export interface CheckboardQAModel {
  [questionId: number]: number[];
}

export interface CheckboardInviteOTPResponseModel {
  userReferenceKey?: string;
  reference?: string;
  kbaResult: KbaResultEnum;
}

export interface InviteKBAClientCheckLeadModel {
  email: string;
  resumeKey: string;
  firstName: string;
  lastName: string;
  dob: string;
  telephone: TelephoneModel;
  address: AddressModel;
  previousAddresses?: AddressModel[];
  introducerId: number;
}

export interface CompleteSoftCheckLeadModel {
  resumeKey: string;
  defendantIdsInSelectedMappings: number[];
  referenceId: string;
  answers: CheckboardQAModel;
  introducerId: number;
}

export interface InviteOTPClientCheckModel {
  email?: string;
  resumeKey: string;
  editKey?: string;
  firstName: string;
  lastName: string;
  dob: string;
  telephone: TelephoneModel;
  address: AddressModel;
  introducerId?: number;
  otpOnly?: boolean;
}

export interface CheckboardInitQAModel {
  kbaResult?: KbaResultEnum;
  detailsMismatch?: boolean;
  notFound?: boolean;
  referenceId?: string;
  questions?: CheckboardSoftCheckQuestionModel[];
}

export interface VerifyOTPClientCheckModel {
  resumeKey: string;
  reference: string;
  code: string;
  introducerId?: number;
}

export interface VerifyOTPResponseModel {
  message: 'ok';
  kbaResult: KbaResultEnum;
}

export class CheckboardService extends ApiV2Service {
  // You are not supposed to instantiate this class using new
  private constructor() {
    super();
  }

  static serviceName = 'CheckboardService';
  static baseUrl = process.env.CBS_URL || 'https://jirlpg3ew9.execute-api.eu-west-1.amazonaws.com';

  protected static logger = (...args: any[]) => {
    if (this.environment === 'dev') {
      console.log(`%c[${this.serviceName}] :: `, 'color:DodgerBlue', args.join(''));
    }
  };

  static inviteClientKBACheck(lead: InviteKBAClientCheckLeadModel): Promise<CheckboardInitQAModel> {
    return this.post<CheckboardInitQAModel>('invite-kba', { lead });
  }

  static completeClientSoftCheck(completeSCM: CompleteSoftCheckLeadModel): Promise<{ creditCheckRef: string; kbaResult: KbaResultEnum }> {
    return this.post<{ creditCheckRef: string; kbaResult: KbaResultEnum }>('complete-kba', completeSCM);
  }
  
  static inviteClientOTPCheck(inviteOTPBOdy: InviteOTPClientCheckModel): Promise<CheckboardInviteOTPResponseModel> {
    return this.post<CheckboardInviteOTPResponseModel>('invite-otp', inviteOTPBOdy);
  }
  
  static verifyClientOTPCheck(verifyOTPBody: VerifyOTPClientCheckModel): Promise<VerifyOTPResponseModel> {
    return this.post<VerifyOTPResponseModel>('verify-otp', verifyOTPBody);
  }
}
