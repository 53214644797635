const setSessionStorage = (key, value) => {
  sessionStorage.setItem(key, JSON.stringify(value));
};

const getSessionStorage = (key) => {
  if (typeof sessionStorage !== `undefined`) {
    return JSON.parse(sessionStorage.getItem(key));
  }

  return null;
};

const clearSessionStorage = (key) => {
  if (typeof sessionStorage !== `undefined`) {
    return sessionStorage.removeItem(key);
  }

  return ``;
};


export { setSessionStorage, getSessionStorage, clearSessionStorage };
