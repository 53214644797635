import { navigate } from 'gatsby';
import { NavigateOptions } from '@reach/router';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { BehaviorSubject, Subscription } from 'rxjs';

// Mute TS About roptoSession in Window
declare const window: Window & { dataLayer: any };

export function formatBytes(bytes: number, decimals: number = 0) {
  let units: string[] = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];

  let i: number = 0;

  for (i; bytes > 1024; i++) {
    bytes /= 1024;
  }

  return parseFloat(bytes.toFixed(decimals)) + units[i];
}

export function useObservable<T>(behaviorSubject: BehaviorSubject<T>): [T, (val: T) => void] {
  try {
    const [value, setValue] = useState<T>(behaviorSubject.value);

    useEffect(() => {
      const sub: Subscription = behaviorSubject.subscribe(setValue);

      // This is the TEARDOWN of the Observable - Removing this will cause memory leak
      return () => sub.unsubscribe();
    }, []);

    return [value, (value: T) => behaviorSubject.next(value)];
  } catch (e) {
    console.error('You cannot use this Hook outside of a component');
    throw e;
  }
}

export function generateUUID() {
  let d = new Date().getTime();
  let d2 = (typeof performance !== `undefined` && performance.now && performance.now() * 1000) || 0;
  return `xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx`.replace(/[xy]/g, (c) => {
    let r = Math.random() * 16;
    if (d > 0) {
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === `x` ? r : (r & 0x3) | 0x8).toString(16);
  });
}

export function isBrowser() {
  return typeof window !== 'undefined';
}

export function inferAddressValues(obj: any) {
  // Create required values from manual addresses
  const { address, fullAddress, county, line1, line2, town, postcode } = obj;

  const inferredAddress = [line1, line2, town].join(` `);
  if (obj && Object.keys(obj).length) {
    return {
      ...obj,
      county: county || town,
      address: address || inferredAddress,
      fullAddress: fullAddress || `${inferredAddress} ${postcode}`,
    };
  }
  return obj;
}

export function parseUrlParams() {
  if (isBrowser()) {
    const urlSearchParams = new URLSearchParams(window.location.search);
    return Object.fromEntries(urlSearchParams.entries());
  }

  return {};
}

export function sortObjectKeys<T extends Object>(unordered: T): T {
  return Object.keys(unordered)
    .sort()
    .reduce((obj: T, key) => {
      obj[key] = unordered[key];
      return obj;
    }, {} as T);
}

export function navigateWithSearchParams<TState, T>(
  pagePath: string,
  opts?: NavigateOptions<TState | unknown>,
  urlParams?: Record<string, string>,
) {
  if (!isBrowser()) {
    return;
  }

  const parsedParams: string = Object.entries(Object.assign(parseUrlParams(), urlParams))
    .map(([k, v]) => `${k}=${v}`)
    .join('&');

  // @ts-ignore
  return navigate(`${pagePath}${parsedParams ? `?${parsedParams}` : ''}`, opts);
}

export function usePersistedState<T>(
  key: string,
  defaultValue: T,
  storage: 'sessionStorage' | 'localStorage' = 'sessionStorage',
): [T, Dispatch<SetStateAction<T>>] {
  if (!isBrowser()) {
    return [defaultValue, () => {}];
  }

  const store = window[storage];

  if (!store) {
    return null;
  }

  const [state, setState] = useState<T>(() => JSON.parse(store.getItem(key)) || defaultValue);

  useEffect(() => {
    store.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, setState];
}

export function diff(o1: unknown, o2: unknown): any {
  return Object.keys(o2).reduce((differences, key) => {
    if (JSON.stringify(o1[key]) === JSON.stringify(o2[key])) {
      return differences;
    }

    return { ...differences, [key]: o2[key] };
  }, {});
}

export function navigateBack() {
  return window?.history.back();
}

// This is already wrapped in `useEffect`
export function blockBackwardsNavigation() {
  if (!isBrowser()) {
    return;
  }

  useEffect(() => {
    const handler = () => history.go(1);

    history.pushState(null, null, location.href);

    // Subscriber
    addEventListener('popstate', handler);
    // Un-Subscriber
    return () => removeEventListener('popstate', handler);
  }, []);
}

export function environmentStage() {
  return process.env.STAGE !== 'production' ? 'dev' : 'prod';
}
