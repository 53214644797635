export enum ReferrerEnum {
  TESTING = 2,
  ROPTO = 4,
  TSL_MEDIA = 5,
  ADVANTAGE_MARKETING = 6,
  ZURU = 7,
  WYBE = 8,
  TCG = 9,
  TCGL = 10,
  CLOCKWORK = 11,
  ADVANTAGAGE_MARKETING_JK = 12,
  CHARTER_HOUSE = 13,
  YLB_X_ACCIO = 14,
  YLB_X_ZAFIRTA = 15,
  APJ = 16,
  ZAFIRTA = 17,
  CLAIM_EAZY = 18,
  RED_STAR = 19,
  ZAFIRTA_X_UNITE = 20,
  AMG_X_ARX = 21,
  RITEWAY = 22,
  ARX_X_ACCIO = 23,
  PARKER_PHILIPS = 24,
  FINLEGAL = 25,
  AMG_X_LEAD_QUOTE = 26,
  ADVANTAGAGE_MARKETING_TCGL_CRM_SPEND = 27,
  TCG_RADIO_X_ACCIO = 28,
  AMG_WAYPOINT = 29,
  TSL_X_TCGL = 30,
  TSL_X_ACCIO = 31,
  FINANCE_CLAIMS_X_ACCIO = 32,
  TCGL_X_CNF = 33,
  TSL_X_ASSERTIS = 34,
  AMG_X_ASSERTIS = 35,
  FINANCE_CLAIMS_X_ASSERTIS = 36,
  MEDIA_MAZE_X_ASSERTIS = 37,
  CAVIS_X_ASSERTIS = 38,
}
