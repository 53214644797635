module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Open Sans:300,400,500,600,700,800","Poppins: 400,600,700,800,900","Inter: 400,500,600,700,800,900","Ubuntu: 400,500,600,700"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TWZZDWVD","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../plugins/ropto-pixels/gatsby-browser.js'),
      options: {"plugins":[],"development":false},
    },{
      plugin: require('../node_modules/@ropto/ropto-session-gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[],"enablePosts":true,"baseUrl":"https://sessions.ropto.com","pageDetails":{"pageId":"845845","pageName":"LP004","brand":"UK PCP CLAIMS","pageVariant":"V002"}},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
