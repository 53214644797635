import { isBrowser } from '@src/lib';

// Mute TS About roptoSession in Window
declare const window: Window & {
  roptoSession: RoptoSession;
};

interface RoptoSessionModel {
  sessionId: string;
  productId: string;
  userAgent: string;
  landingPage: {
    id: string;
    name: string;
    brand: string;
    variant: string;
    url: string;
    searchString: string;
    params: Record<string, string>;
  };
}

interface SendEventArgs {
  action: string;
  label?: string;
  value?: string;
}

interface RoptoSession {
  updateSession: (args: any) => void;
  getSession: () => RoptoSessionModel;
  sendSessionEvent: (arg: SendEventArgs) => void;
}

function wait(n: number) {
  return new Promise((resolve) => setTimeout(resolve, n));
}

export class RoptoSessionService {
  static get session(): RoptoSession | undefined {
    if (isBrowser()) {
      return window?.roptoSession;
    }

    console.log('RoptoSessionModel not available!');
    return;
  }

  static async updateProductId(productId: string, retry = 3) {
    if (retry < 0) return Promise.reject();

    if (!RoptoSessionService.session) {
      await wait(1000);

      console.log(`Waiting for Session: [${3 - retry}/3]`);
      return RoptoSessionService.updateProductId(productId, retry);
    }

    return RoptoSessionService.session?.updateSession({ productId });
  }

  static getSession(): RoptoSessionModel {
    return RoptoSessionService.session?.getSession();
  }

  static sendEvent(arg: SendEventArgs) {
    return RoptoSessionService.session?.sendSessionEvent(arg);
  }
}
