export enum KbaResultEnum {
  VERIFICATION_FAIL = 'verification-fail',
  NO_MATCH = 'no-match',
  FILTERED_NO_MATCH = 'filtered-no-match',
  PARTIAL_MATCH = 'partial-match',
  FOUND = 'found',
  ERROR = 'error',
  CLIENT_NOT_FOUND = 'client-not-found',
  POSSIBLE_DUPLICATE_CLIENT = 'possible-duplicate-client',
  AWAITING_RESULTS = 'awaiting-results',
  OFFLINE_CHECK = 'offline-check',
  KBA_NOT_ANSWERED = 'kba-not-answered',
  OTP_SENT = 'otp-sent',
  OTP_RESENT = 'otp-resent',
  OTP_INCORRECT = 'otp-incorrect',
  OTP_CONFIRMED = 'otp-confirmed',
  OTP_REQUEST_FAILED = 'otp-request-failed',
  OTP_CANNOT_VERIFY_PHONE = 'otp-cannot-verify-phone',
}
