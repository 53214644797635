export abstract class ApiV2Service {
  protected static readonly serviceName: string;
  protected static readonly baseOptions: RequestInit;
  protected static readonly baseUrl: string;

  static readonly environment: 'dev' | 'prod' = process.env.STAGE !== 'production' ? 'dev' : 'prod';

  protected static defaultHeaders: HeadersInit = {
    'Content-Type': 'application/json',
  };

  protected static get basePath() {
    return `${this.baseUrl}/${this.environment}`;
  }

  /** ----------------- Getter/Setter Methods -------------------------  */

  private static async fetchRequest<T>(url: string, opts: RequestInit): Promise<T> {
    return (await fetch(url, opts)).json();
  }

  protected static async get<T>(path: string, opts: RequestInit = this.baseOptions): Promise<T> {
    return this.fetchRequest<T>(`${this.basePath}/${path}`, { ...opts, method: 'GET' });
  }

  protected static async post<T>(path: string, body: any, opts: RequestInit = this.baseOptions): Promise<T | undefined> {
    return this.fetchRequest<T>(`${this.basePath}/${path}`, {
      body: JSON.stringify(body),
      ...opts,
      method: 'POST',
    });
  }

  protected static async put<T>(path: string, body: any, opts: RequestInit = this.baseOptions): Promise<T | undefined> {
    return this.fetchRequest<T>(`${this.basePath}/${path}`, {
      body: JSON.stringify(body),
      ...opts,
      method: 'PUT',
    });
  }

  protected static async patch<T>(path: string, body: any, opts: RequestInit = this.baseOptions): Promise<T | undefined> {
    return this.fetchRequest<T>(`${this.basePath}/${path}`, {
      body: JSON.stringify(body),
      ...opts,
      method: 'PATCH',
    });
  }
}
