import { LdsService, LpServicesService, MdsService } from '@service';
import { VictaDupeCheckClient } from '@ropto/victa-dupe-check-client';
import { CreditCheckProviderEnum, VictaProductEnum } from '@enum';
import { environmentStage } from '@src/lib';

// 50/50 Split Test
// if (isBrowser() && process.env.REDIRECT_URL && Math.floor(Math.random() * 100) % 2 !== 0) {
//   window.location.href = `${process.env.REDIRECT_URL}${location.search || ''}`;
// }

const integrationApiKey = {
  prod: 'LfkGOpxiVk4qDDsoCOshi6e3X8yyKboY2MKmR7Xa',
  dev: '2AsqgKoYZ4aRX3wkANLkIAlZcVMuW886kKNKMGef',
};

export const ldsService: LdsService = new LdsService({
  baseUrl: process.env.LDS_URL || 'https://fdybjfbgkf.execute-api.eu-west-1.amazonaws.com',
  opts: { productId: '427', clientId: '94', creditCheckProvider: CreditCheckProviderEnum.VALID_8 },
  initHeaders: { 'x-api-key': integrationApiKey[environmentStage()] },
});

export const mdsService: MdsService = new MdsService({
  baseUrl: process.env.MDS_URL || 'https://v7njlb8x7i.execute-api.eu-west-2.amazonaws.com',
  // opts: { productId: '333', clientId: '1' },
  opts: { clientId: '1' },
  storageKey: 'mdsLeadId',
  resumeKeyKey: 'tcglLp030Resume',
});

export const lpServicesService: LpServicesService = new LpServicesService({
  baseUrl: process.env.LP_SERVICES_URL || 'https://1spvcfs52c.execute-api.eu-west-1.amazonaws.com',
  opts: { product: VictaProductEnum.UNDISCLOSED_COMMISSION },
});

export const vdcService = new VictaDupeCheckClient(process.env.VDC_URL || 'https://9kks2axbzh.execute-api.eu-west-1.amazonaws.com/dev/');
